import React, {Fragment, createContext, useContext, useEffect, useRef, useState} from 'react';
import { useRouter } from "../../components/Shared/Router/Router";
import { AuthContext } from "../Auth/AuthProvider";
import { OrganizationContext } from "../Organization/Organization";


import { hasPermission } from "../../components/Dashboard/reactPermissionsWrapper";
import Axios_client from "../../utils/axios_client";
import Swal from "@molline/sweetalert2";

import de from  '../../components/Dashboard/tables/datatable.de.json';
import capitalizeUtil from "../../utils/capitalizeUtils";
const $ = require('jquery');
$.DataTable = require('datatables.net');


export const GroupsContext = createContext();

const GroupProvider = ({ children, context }) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);
    const [groups, setGroups] = useState([]);
    const [group, setGroup] = useState({});
    const groupsChanged = useCompare(groups);
    const groupChanged = useCompare(group)
    const { organization } = useContext(OrganizationContext);
    const organizationChanged = useCompare(organization)

    const [updatedGroup, setUpdatedGroup] = useState({})


    useEffect(() => {

        if(organizationChanged === true && !!organization._id) { setGroup({}); getGroups() } 
        if(groupChanged){

        }
        
    }, [user, groups, group, groupsChanged, groupChanged, organization, organizationChanged])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const groupSelect = () => {
        if(groups.length > 0){
            let orderedGroups = [...groups]
            orderedGroups.sort(function (a, b) {
                return a.name.localeCompare(b.name, undefined, {
                    numeric: true
                })
            });
            return (
                <Fragment>
                    <div className="col-12">
                        <select className="form-select w-100" id="groupSwitch"  onChange={(e) => {setGroup(orderedGroups.filter(grp => grp?._id === e.currentTarget.value)[0]) }}>
                            <option value="" selected>Bitte Gruppe wählen</option>
                            {orderedGroups.map((opt, i) => {
                                return (<option value={`${opt._id}`} selected={group?._id === opt._id} >{opt.name}</option>)
                            })}
                        </select>
                    </div>
                    
                </Fragment>
            )
        }else{
            return (
                <Fragment></Fragment>
            )
        }

    }


    const errorToast = (err) => {
        if(!err) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger text-white"
            }
        })
    }

    const successToast = (msg) => {
        if(!msg) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: msg,
            showConfirmButton: false,
            customClass: {
                popup: "bg-success text-white"
            }
        })
    }

    const doYouReallyWantToSave = () => {
        return new Promise(async (resolve) => {
            const answer = await Swal.fire({
                title: 'Sicher?',
                text: 'Damit werden die Daten gespeichert',
                showCancelButton: true,
                confirmButtonText: 'Ja',
                cancelButtonText: "Nein",
            })
            if (!answer.isConfirmed) return resolve(false);
            return resolve(true);
        })
    }
    /**
     * GET ORGANIZATION LEVEL GROUPS
     * Groups are pre filtered in the backend
     */
    const getGroups = () => {
        if(!organization) return;
        Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/group/all?organization_id=${organization._id}`)
        .then( response => {
            setGroups(response.data);
            if( !hasPermission({user, scopes:['location.view.any']}) ){
                //if(response.data.length === 1) setGroup(response.data[0])
            }
        })
        .catch( err => {
            return
        }) 
    }

    const getGroup = (group_id) => {
        if(!organization) return;
        Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/group/?_id=${group_id}&organization_id=${organization._id}`)
        .then( response => {
            setGroup(response.data); 
        })
        .catch( err => {
            errorToast(err.message)
            return
        }) 
    }

    const createGroup = async () => {
        if( hasPermission({user, scopes:['group.create.any', 'group.create.own']}) ){
            Swal.fire({
                allowOutsideClick: true,
                icon:"success",
                backdrop: true,
                width: "60%",
                title: "Neue Gruppe erstellen",
                html: ``+
                `<div class="text-start p-1">
                    <p class="mb-4">Bitte stellen Sie sicher, dass sie im richtigen Kunden sind. Dies wird eine neue Gruppe für den Kunden <span class="text-danger"><b>${organization.name}</b></span> erstellen. Bitte fügen Sie nach dem Erstellen auf der Gruppen-Detail-Seite noch Einheiten & Verwalter hinzu.</p>` + 
                `   <div class="form-group mb-1">`+
                `       <label for="groupTypeSelect" class="mb-1">Gruppentyp</label>`+
                `       <div class="row">` +
                `           <div class="col">` +
                `               <select class="form-select form-control-lg" id="groupTypeSelect">`+
                `                   <option value="locations" selected>Liegenschaft</option>`+
                `                   <option value="calculation">Berechnung</option>`+
                `               </select>`+
                `           </div>` +
                `           <div class="col hidden" id="colAttributes">` +
                `               <select class="form-select form-control-lg is-invalid" id="groupTypeAttributes">` +
                `                   <option value="none" selected>Calculation Group Type</option>`+
                `                   <option value="hzg" >Heizungs</option>`+
                `                   <option value="wsr">Heiß Wasser</option>`+
                `               </select>`+
                `           </div>` +
                `       </div>` +
                `       <small>Group types identify the function of the group. Location groups are for managing permissions locations. These are visible to admins and managers. A Calcualtion group type if for special build conditions like heat pumps which may fuel many buildings. This group type is only visible to you. It will affect all Consuption reporting and calculations.</small>`+
                `   </div>`+
                `   <div class="form-group mb-1"><label class="text-left mb-1">Gruppename</label>` +
                `       <input type="text" id="txtGroupName" class="form-control form-control-lg " placeholder=""></input>`+
                `       <small>Enter a meaningful name for this group</small></div>`+
                `   </div>`+
                `</div>` ,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: `Add group to ${organization.name}`,
                customClass:{
                    icon:"text-justify",
                },
                didOpen: () => {
                    let grpTypeSelect = Swal.getPopup().querySelector('#groupTypeSelect');
                    let grpAttributeSelect = Swal.getPopup().querySelector('#groupTypeAttributes')
                    grpTypeSelect.addEventListener('change', (e) => {
                        if(e.target.value === 'calculation') Swal.getPopup().querySelector('#colAttributes').classList.remove('hidden');
                        if(e.target.value === 'locations') Swal.getPopup().querySelector('#colAttributes').classList.add('hidden');
                    })
                    if(grpAttributeSelect.value === 'none'){
                        grpAttributeSelect.classList.add('is-invalid');
                        grpAttributeSelect.classList.remove('is-valid');
                    }
                    grpAttributeSelect.addEventListener('change', (e) => {
                        if(e.target.value !== 'none'){
                            grpAttributeSelect.classList.add('is-valid');
                            grpAttributeSelect.classList.remove('is-invalid');
                        }else{
                            grpAttributeSelect.classList.add('is-invalid');
                            grpAttributeSelect.classList.remove('is-valid');
                        }
                    })
                },
                preConfirm: () => {
                    const type = Swal.getPopup().querySelector('#groupTypeSelect').value
                    const name = Swal.getPopup().querySelector('#txtGroupName').value
                    let attribute = Swal.getPopup().querySelector('#groupTypeAttributes').value

                    if(type !== 'calculation') attribute = undefined;
                    if(type === 'calculation' && attribute === 'none') Swal.showValidationMessage(`REJECTED! You have selected a Calculation Group Type but have NOT selected an attribute type.`)

                    return {
                        type,
                        name,
                        attribute
                    }
                } 
            })
            
            .then(async result => {
                //console.log(result)
                if(result.isConfirmed){
                    Axios_client.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/group`, {
                        name: result.value.name,
                        type: result.value.type,
                        organization_id: organization._id,
                        attribute: result.value.attribute,
                    })
                    .then((response) => {
                        successToast(`Ihre neue Gruppe, ${result.value.name}, wurde erstellt: ${response.data._id}`)
                        getGroups()
                        router.push(`/details/group/${response.data._id}`)
                        return response.data
                    })
                    .catch(err => {
                        errorToast(err.message)
                    })
                }
            })
        }else{ return }
        
    }

    const updateGroup = async (updated_group) => {
        return new Promise( async (resolve, reject) => {
            if( hasPermission({user, scopes:['group.update.any', 'group.update.own']}) ){
                Axios_client.backendClient().put(`${process.env.REACT_APP_API_OPENMETER}/group`, updated_group)
                .then( response => {
                    setGroup(response.data)
                    getGroups()
                    successToast('Gruppe wurde erfolgreich bearbeitet')

console.log(response.data)


                    return resolve(response.data)
                })
                .catch(err => {
                    errorToast(`Fehler beim bearbeiten der Gruppe (83): ${err.message}`);
                    return reject()
                })
            }else{ return reject() }
        })
    }

    const removeMember = async (memberId) => {

console.log('REMOVE MEMBER', memberId)


        try {
            const doSave = await doYouReallyWantToSave()
            if (!doSave) {
                return
            }
            const newGroupMembers = group.members.filter(mem => mem !== memberId)
            group.members = newGroupMembers

        console.log('REMOVE MEMBER', group.members)



            updateGroup(group)
        } catch (e) {
            console.log('error at removing group member', e);
            errorToast('Entfernen war nicht erfolgreich')
        }
    }

    const addUser = async (mgmtUsers) => {
        let uGroup = {...group}
        const addUserTbl = () => {
            if (mgmtUsers.length !== 0) {
                $('#tblAddGroupUsers tbody').off('click')
                const userAddTable = $('#tblAddGroupUsers').DataTable({
                    language: de,
                    paging: true,
                    pagingType: "full",
                    processing: true,
                    searching: true,
                    data: mgmtUsers,
                    orderCellsTop: true,
                    fixedHeader: true,
                    rowId: "_id",
                    pageLength: 50,
                    order: [[1,"asc"] ],
                    columns: [
                        {
                            "data": "organization", render: function (data, type, row) {
                                return data.name;
                            }
                        },
                        {
                            "data": "first_name", render: function (value, type, row) {
                                return capitalizeUtil.capitalize(value);
                            }
                        },
                        {
                            "data": "last_name", render: function (value, type, row) {
                                return capitalizeUtil.capitalize(value);
                            }
                        },
                        { "data": "emails[0].email" },
                        { "data": "roles" },
                    ],
                    createdRow: function (row, data, dataIndex) {
                        if(group.users.includes(data._id)) $(row).addClass('selected');
                    },
                    "fnDrawCallback": function( oSettings ) {
                        
                    }
                })
                $('#tblAddGroupUsers tbody').on('click', 'tr', function () {
                    let userData = mgmtUsers.filter(usr => usr._id === this.id)[0]
                    $(`#tblAddGroupUsers tr[id=${this.id}]`).toggleClass('selected')
                    const idArray = $.map(userAddTable.rows('.selected').data(), function (item) {
                        return item._id
                    })
                    uGroup.users = idArray
                });
            }
        }

        Swal.fire({
            width: "90%",
            title: 'Verwalter zur Gruppe hinzufügen',
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Hinzufügen',
            cancelButtonText: 'Abbrechen',
            html:
                `Nach Nutzer suchen und diese per Klick auswählen, um sie der aktuellen Gruppe zuzuordnen.
                <table id="tblAddGroupUsers" className="table mb-0 row-border hover order-column w-100" role="grid">
                    <thead>
                        <tr>
                            <th >Organisation</th>
                            <th >Vorname</th>
                            <th >Nachname</th>
                            <th >Email</th>
                            <th >Rolen</th>
                        </tr>
                    </thead>
                </table>`,
            willOpen: () => {
                addUserTbl()
            },
            preConfirm: (inputValue) => {}
        })
        .then(async results => {
            if (results.isConfirmed) {
                updateGroup(uGroup)
            }
            $('#tblAddGroupUsers').DataTable().destroy();
        })
    }

    const groupIcon = ({group, size = 1}) => {
        if(group.type === 'locations') return `<i title="Location Group" class="fa-solid fa-building text-success fa-${size}x">`
        if(group.type === 'calculation') return `<i title="Calculation Group" class="fa-solid fa-calculator-simple text-info fa-${size}x"></i>`

        if(group.type === 'users') return `<i title="User Group" class="fa-solid fa-users text-secondary fa-${size}x"></i>`
        if(group.type === 'sensors') return `<i title="Sensors Group" class="fa-solid fa-microchip text-primary fa-${size}x"></i>`

        if(group.type === 'gateways') return `<i title="Gateway Group" class="fa-solid fa-router text-danger fa-${size}x"></i>`
        if(group.type === 'groups') return `<i class="text-info fa-solid fa-house fa-${size}x"></i>`

        return `<i class="text-danger fa-regular fa-building-circle-xmark fa-${size}x"></i>`
    }

    const removeUser = async (userId) => {
        try {
            const newGroupUSers = group.users.filter(usr => usr !== userId)
            group.users = newGroupUSers
            updateGroup(group)
        } catch (e) {
            console.log('error at removing group user', e);
            errorToast('Entfernen war nicht erfolgreich')
        }
    }
    /**
     * Destroy ALL Members
     */
    const resetMembers = () => {
        Swal.fire({
            icon:"error",
            title: 'Erase Group Members',
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Reset Members',
            cancelButtonText: 'Abbrechen',
            text: "Wenn Sie die Nutzer-Mieteinheiten  der Gruppe zurücksetzen, werden alle bisher hinzugefügten Nutzer-Mieteinheiten  von dieser Gruppe entfernt. Danach können wieder neue Nutzer-Mieteinheiten  zur Gruppe hinzugefügt werden",
            willOpen: () => {},
            preConfirm: () => {}
        })
        .then(async results => {
            if (results.isConfirmed) {
                let uGroup = {...group}
                uGroup.members = []
                updateGroup(uGroup);
            }
        })
        
    }

    const resetUsers = () => {
        Swal.fire({
            icon:"error",
            title: 'Erase Group Users',
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Reset Users',
            cancelButtonText: 'Abbrechen',
            text: "Wenn sie alle Verwalter der Gruppe zurücksetzen, werden alle bisher hinzugefügten Verwalter aus der Gruppe entfernt. Diese Verwalter haben dann möglicherweise auch keinen Zugriff auf die Gruppe mehr. Sie können im Anschluss natürlich wieder neue Verwalter hinzufügen",
            willOpen: () => {},
            preConfirm: () => {}
        })
        .then(async results => {
            if (results.isConfirmed) {
                let uGroup = {...group}
                uGroup.users = []
                updateGroup(uGroup);
            }
        })
        
    }

    const deleteGroup = async (group_id) => {
        if( hasPermission({user, scopes:['group.delete.any', 'group.delete.own']}) ){
            Swal.fire({
                allowOutsideClick: true,
                backdrop: true,
                width: "60%",
                title: "Diese Gruppe löschen",
                html: `<div class="text-left"><p>Sie sind gerade dabei, die Gruppe <b>${group_id}</b> zu <span class="text-danger">LÖSCHEN</span></p>` +
                `<p>Wenn Sie eine Gruppe Löschen, wird diese von der Plattform entfernt. Dieser Vorgang löscht keine Nutzer-Mieteinheiten  oder Verwalter.`+
                `Sie können im Anschluss neue Gruppen erstellen.</p>`+
                `</div>` ,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: `Gruppe ${group_id} löschen`,
                customClass:{
                    icon:"text-justify",
                },
                icon: 'error',
                
            })
            .then(async result => {
                if(result.isConfirmed){
                    Axios_client.backendClient().delete(`${process.env.REACT_APP_API_OPENMETER}/group?id=${group_id}`)
                    .then( response => {
                        getGroups()
                        successToast(`Gruppe ${group_id} wurde erfolgreich gelöscht.`)
                        return;
                    })
                    .catch(err => {
                        errorToast(`Beim Löschen der Gruppe ist ein Fehler aufgetreten: ${err}`)
                        return 
                    })
                }
            })
        }else{ return  }
        
    }

    const addGroupMember = ({locations}) => {
        console.log('locations', locations)
        let uGroup = {...group}
        const addLocationTbl = () => {
            if (locations.length !== 0) {
                const memberAddTable = $('#tblGroupMembers').DataTable({
                    language: de,
                    paging: true,
                    pagingType: "full",
                    processing: true,
                    searching: true,
                    data: locations,
                    orderCellsTop: true,
                    fixedHeader: true,
                    rowId: "_id",
                    pageLength: 100,
                    "lengthMenu": [[100, 250, -1], [100, 250, "Alle"]],
                    order: [[1,"asc"],[2,"asc"],[3,"asc"] ],
                    columns: [
                        {
                            "data": null,
                            "searchable": false,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.type === 'multi_premise') {
                                    return `<i class="fa-solid fa-building text-primary"></i>`;
                                } else {
                                    return '<i class="fa-solid fa-house text-info"></i>';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.street !== undefined) {
                                    return capitalizeUtil.capitalize(data.address.street)
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.house_number !== undefined) {
                                    return data.address.house_number
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.street1 !== undefined) {
                                    return data.address.street1
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.city !== undefined) {
                                    return capitalizeUtil.capitalize(data.address.city)
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.post_code !== undefined) {
                                    return data.address.post_code
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                return 'DE'
                            }
                        },
                    ],
                    createdRow: function (row, data, dataIndex) {
                        if(group.members.includes(data._id)) $(row).addClass('selected');
                    },
                    drawCallback: function( settings ) {

                        console.log('DRAW CALLBACK')
                        
                    }
                })
                $('.dataTables_length select').addClass('form-select');
                $('.dataTables_filter input').addClass('form-control');

                $('#tblGroupMembers tbody').on('click', 'tr', function (e) {
                    $(this).toggleClass('selected')
                    
                    let locationData = locations.filter(loc => loc._id === this.id)[0]
                    if(locationData.type === 'multi_premise'){
                        $.map(memberAddTable.rows().data(), function (item) {
                            if( locationData.children.includes(item._id) ){
                                $(`#tblGroupMembers tr[id=${item._id}]`).addClass('selected')
                            }
                        })
                    }
                    const idArray = $.map(memberAddTable.rows('.selected').data(), function (item) {
                        return item._id
                    })
                    uGroup.members = idArray
                });
            }
        }
        Swal.fire({
            width: "90%",
            title: 'Nutzer-Mieteinheiten  zur Gruppe hinzufügen',
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Hinzufügen',
            cancelButtonText: 'Abbrechen',
            html:
                `Suchen Sie nach Nutzer-Mieteinheiten  und wählen Sie diese per Klick aus, um sie der aktuellen Gruppe zuzuordnen.
                <table id="tblGroupMembers" className="table mb-0 row-border hover order-column w-100" role="grid">
                    <thead>
                        <tr>
                            <th></th>
                            <th >Straße</th>
                            <th >Hausnr.</th>
                            <th >ME</th>
                            <th >Stadt</th>
                            <th >PLZ</th>
                            <th >Land</th>
                        </tr>
                    </thead>
                </table>`,
            willOpen: () => {
                addLocationTbl()
            },
            preConfirm: (inputValue) => {}
        })
            .then(async results => {
                if (results.isConfirmed) {
                    updateGroup(uGroup)
                }
                $('#tblGroupMembers').DataTable().destroy();
            })
    }

    const actions = {
        groups,
        group,
        setGroup,
        setGroups,
        getGroups,
        createGroup,
        updateGroup,
        deleteGroup,
        getGroup,
        errorToast,
        successToast,
        doYouReallyWantToSave,
        updatedGroup, 
        setUpdatedGroup,
        addGroupMember,
        resetMembers,
        removeMember,
        removeUser,
        addUser,
        resetUsers,
        groupChanged,
        groupSelect,
        groupIcon
    }

    return (
        <Fragment>
            <GroupsContext.Provider value={actions}>
                {children}
            </GroupsContext.Provider>
        </Fragment>
    )

}

export default GroupProvider;