import React, {Fragment, useEffect, useContext } from 'react';
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import { OrganizationContext } from "../../../../context/Organization/Organization";
import { LocationsContext } from '../../../../context/Locations/Locations'

import Swal from "@molline/sweetalert2";
import Axios_client from "../../../../utils/axios_client";
import { PermissionsWrapper, hasPermission, MembershipWrapper, hasMembership } from "../../reactPermissionsWrapper";
import HelpProvider from "../../helpProvider/helpProvider";
import { GroupsContext } from '../../../../context/Groups/Groups';

const ReportSensorMeasurementsErasWidget = (props) => {

    const { user } = useContext(AuthContext);
    const { organization, organizationChanged } = useContext(OrganizationContext);
    const { getAllLocations } = useContext(LocationsContext)
    const { groups } = useContext(GroupsContext)
    useEffect(  () => {

    }, [ organization, organizationChanged, groups ]);

    const capitalize = (str) => {
        if (!str) return str;
        if (str.indexOf("-") > -1)
            return str.split("-").map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`).join("-")
        return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
    }

    const errorToast = (err) => {
        if (!err) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err?.response ? JSON.stringify(err.response.data) : err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger text-white"
            }
        })
    }

    const successToast = (msg) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: msg,
            showConfirmButton: false,
            customClass: {
                popup: "bg-success"
            }
        })
    }

    const downloadReport = async () => {
        document.getElementById('report_tab_close').click();
        let yearArray = []
        let now = new Date().getFullYear() - 1;
        yearArray.push(now)
        for(let x = 1; x < 5; x++){
            yearArray.push(now - x)
        }
        
        // cant use locations context because it might be filtered by group
        try {
            var unfilteredLocations = await getAllLocations({filter: false})
        } catch (err) {
            console.log('error at getting all locations');
        }
        const multiPremiseLocations = unfilteredLocations.filter(loc => loc.type === 'multi_premise')
       
        let html = `
            <p style="text-align:left">Für ERAS geeigneten Jahres-End-Bericht herunterladen.
            Dieser Bericht beinhaltet alle Zähler der ausgewählten Gruppe.</p>
            <p>Kunde: ${organization.name}</p>
            <select class="form-select mb-1" id="year">`;
        
                yearArray.map(y => {
                    if(y > 2020) html += `<option value="${y}" ${(y === now)?"selected": ""} >${y}</option>`
                })
        
        html += `</select>
            <p>Gruppe</p>`

            if(groups.length === 0) html += `<p>Es wurden noch keine Gruppen hinzugefügt!</p>`
            else {
                html += `<select class="form-select mb-1" id="group_id">`
                groups.map(group => {
                    html += `<option value="${group._id}">${group.name} (${group._id})</option>`
                })
                html += '</select'
            }

        Swal.fire({
            icon: "question",
            width: "35rem",
            title: `Bericht herunterladen `,
            allowOutsideClick: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Herunterladen',
            cancelButtonText: 'Abbrechen',
            html: html,

            preConfirm: () => {
                const group_id = Swal.getPopup().querySelector('#group_id').value
                const year = Swal.getPopup().querySelector('#year').value

                return {
                    group_id,
                    year
                }
            }
        })
        .then(async (results) => {
            if (!results.isConfirmed || results.isDenied || results.isDismissed) return;

            let params = {
                organization_id: organization._id,
                year: results.value.year, //We have not recorded anything prior to 2021
                group_id: results.value.group_id
            }

            Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/report/endyear/eras?organization_id=${params.organization_id}&group_id=${params.group_id}&year=${params.year}`, params)
                .then(response => {})
                .catch(err => {
                    console.log(err)
                })
        })

    }

    const downloadReportnew = async () => {
        document.getElementById('report_tab_close').click();
        let yearArray = []
        let now = new Date().getFullYear()
        yearArray.push(now)
        for(let x = 1; x < 5; x++){
            yearArray.push(now - x)
        }
        
        // cant use locations context because it might be filtered by group
        try {
            var unfilteredLocations = await getAllLocations({filter: false})
        } catch (err) {
            console.log('error at getting all locations');
        }
        const multiPremiseLocations = unfilteredLocations.filter(loc => loc.type === 'multi_premise')
       
        let html = `
            <p style="text-align:left">Für ERAS geeigneten Jahres-End-Bericht herunterladen.
            Dieser Bericht beinhaltet alle Sensoren der ausgewählten Gruppe.</p>
            <p>Kunde: ${organization.name}</p>
            <select class="form-select mb-1" id="year">`;
        
                yearArray.map(y => {
                    if(y > 2020) html += `<option value="${y}" ${(y === now-1)?"selected": ""} >${y}</option>`
                })
        
        html += `</select>
            <p>Gruppe</p>`

            if(groups.length === 0) html += `<p>Es wurden noch keine Gruppen hinzugefügt!</p>`
            else {
                html += `<select class="form-select mb-1" id="group_id">`
                groups.map(group => {
                    html += `<option value="${group._id}">${group.name} (${group._id})</option>`
                })
                html += '</select'
            }

        Swal.fire({
            icon: "question",
            width: "40rem",
            title: `Bericht herunterladen `,
            allowOutsideClick: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Herunterladen',
            cancelButtonText: 'Abbrechen',
            html: html,

            preConfirm: () => {
                const group_id = Swal.getPopup().querySelector('#group_id').value
                const year = Swal.getPopup().querySelector('#year').value

                return {
                    group_id,
                    year
                }
            }
        })
        .then(async (results) => {
            if (!results.isConfirmed || results.isDenied || results.isDismissed) return;

            let params = {
                organization_id: organization._id,
                year: results.value.year, //We have not recorded anything prior to 2021
                group_id: results.value.group_id
            }

            Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/report/endyear/erasnew?organization_id=${params.organization_id}&group_id=${params.group_id}&year=${params.year}`, params)
                .then(response => {
                    const link = `${process.env.REACT_APP_API_FILESHARE}/download/${response.data}`
                    const element = document.createElement("a");
                    element.href = link
                    element.download = `eras_report_${params.organization_id}_${params.group_id}_${params.year}.csv`;
                    document.body.appendChild(element);
                    element.click();
                })
                .catch(async(err) => {
                    if(err.response.data === 'No Eras-Report available') {
                        const youSure = await Swal.fire({
                            icon: "info",
                            title: 'Bericht ist leer!',
                            html: '<span>Wollen Sie versuchen, den Bericht zu generieren?</span>',
                            showCancelButton: true,
                            confirmButtonText: 'Bericht generieren',
                            cancelButtonText: "Abbrechen",
                        })
                        if (!youSure.isConfirmed) return

                        try {
                            await Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/report/report/endyear/eras/generate?organization_id=${params.organization_id}&group_id=${params.group_id}&year=${params.year}`)
                            successToast('Bericht wurde erfolgreich generiert und kann jetzt heruntergeladen werden')
                        } catch (err) {
                            console.log('error at generating eras report', err);
                            errorToast(err)
                        }
                    }else {
                        errorToast(err)
                    }
                })
        })

    }

    const UI = () => {
       
        return (
            <PermissionsWrapper user={user} scopes={['sensor.view.any', 'sensor.view.own']} >
               <div className="col-xl-4 col-md-6">
                    <div className="card text-center clickable" >
                        <HelpProvider reportSampleImgPath="/theme2/app-assets/images/reports/sensor_measurement_report.jpg" title="ERAS Jährlich Messbericht" descriptionText="This is an annual sensor measurements report for ERAS import." />
                        <div className="card-body" onClick={() => { downloadReportnew() }}>
                            <div className="mx-auto my-1">
                                <i className="fa-solid fa-download font-primary fa-4x"></i>
                            </div>
                            <p className="text-muted mb-0 line-ellipsis"
                                title="">ERAS Jahres-End-Bericht (neu)</p>
                        </div>
                    </div>
                </div>
            </PermissionsWrapper >
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default ReportSensorMeasurementsErasWidget;